// import Navbar from "./components/Navbar"
import Panels from "./components/Panels"
import Canvas from "./components/Canvas"
import Footer from "./components/Footer"
import Toolbox from "./components/Toolbox"
import EditorContainer from "./components/EditorContainer"
import ContextMenu from "./components/ContextMenu"
import { useEffect, useState } from "react"
import { checkOwnershipByID } from "~/services/chekcProduct"
import { useSelector } from "react-redux"
import { isOwnerSelector } from "~/store/slices/auth/selectors"
import axios from "axios"
// import Header from "~/components/General/Header"
// @ts-ignore
import { Header } from "spt-ui-react/output"
import Navbar from "~/components/General/Navbar"

import TUser from "~/models/user"

const SERVER = import.meta.env.VITE_SERVER_URL

async function fetchInfo(token: string): Promise<TUser> {
  const url = `${SERVER}/api/account`
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const info = response.data.data

  return info as TUser
}

const GraphicEditor = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [info, setInfo] = useState<TUser | null>(null)
  const [token, setToken] = useState<string>("")

  const checkToken = async () => {
    const token = sessionStorage.getItem("token")
    if (!token) {
      window.location.href = "/login"
    }

    setToken(token as string)

    const info = await fetchInfo(token as string)
    setInfo(info)
    setIsLoading(false)
  }
  useEffect(() => {
    checkToken()
  }, [])

  console.log("info", info)

  if (isLoading) return <div>Loading...</div>

  const logout = () => {
    sessionStorage.removeItem("token")
    window.location.href = "/login"
  }

  return (
    <EditorContainer>
      {/* @ts-ignore */}
      <div className="relative">
        <Header token={token} info={info} logout={logout}></Header>
      </div>
      <div className="text-center  flex items-center justify-center border">
        {/* <Navbar info={info} token="" /> */}
      </div>
      <div style={{ display: "flex", flex: 1, marginTop: "17px" }}>
        <Panels />
        <div style={{ flex: 1, display: "flex", flexDirection: "column", position: "relative" }}>
          <Toolbox />
          <Canvas />
          <Footer />
        </div>
      </div>
    </EditorContainer>
  )
}

export default GraphicEditor
