import React, { useEffect, useState } from "react"
import { useEditor } from "@layerhub-io/react"
import { Block } from "baseui/block"
import Scrollable from "~/components/Scrollable"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { getAllDigitalAssetsImages } from "~/services/digital-assets"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { SIZE, Spinner } from "baseui/spinner"
import "react-lazy-load-image-component/src/effects/blur.css"
import { v4 as uuidv4 } from "uuid"
import Search from "~/components/Icons/Search"
import Fuse from "fuse.js"
import { useSelector } from "react-redux"
import { isOwnerSelector } from "~/store/slices/auth/selectors"
import { Input } from "~/components/ui/input"
import { ArrowLeftCircleIcon, FolderIcon, ChevronLeftCircle } from "lucide-react"
import { SearchIcon } from "lucide-react"
import { useDispatch } from "react-redux"
import { setElementsData } from "~/store/slices/assets/actions"
import { selectElementsData } from "~/store/slices/assets/selectors"

const spaceBaseUrl = "https://booksbytitans-bucket.sgp1.digitaloceanspaces.com"

const convertFilesToFolderStructure = (files: any) => {
  const folderSystem: any = []

  files.forEach((file: any) => {
    const pathComponents = file.fpath.split("/")
    let currentLevel = folderSystem

    pathComponents.forEach((component: any, index: number) => {
      const isLast = index === pathComponents.length - 1

      const existingItem = currentLevel.find((item: any) => item.name === component)

      if (existingItem) {
        if (isLast) {
          existingItem.files.push({
            type: "file",
            file: file,
          })
        } else {
          currentLevel = existingItem.children
        }
      } else {
        const newItem = {
          type: isLast ? "file" : "dir",
          name: component,
          children: [],
          files: isLast ? [{ type: "file", file: file }] : [],
        }

        currentLevel.push(newItem)

        if (!isLast) {
          currentLevel = newItem.children
        }
      }
    })
  })

  return folderSystem
}

function Folder({ name, file, count = 0, folderNames = [] }: any) {
  const isOwner = useSelector(isOwnerSelector).isOwner
  count++

  const [expand, setExpand] = useState(false)
  const editor = useEditor()
  const addObject = React.useCallback(
    (url: string) => {
      if (!isOwner) {
        alert("To add images to the canvas, one needs to purchase the Coloring Book Maker tool first.")
        return
      }
      const id = uuidv4()
      const finalUrl = spaceBaseUrl + "/coloring-book-maker-assets-public" + url
      const options = {
        id: id,
        type: "StaticImage",
        src: finalUrl,
      }
      editor.objects.add(options).then(() => {
        // editor.objects.resize("height", 200, "test")
        // editor.objects.resize("width", 200, "test")
        editor.objects.scale("fit", id)
      })
    },
    [editor, isOwner]
  )
  return (
    <div className="flex  flex-col">
      <div className="flex flex-row  justify-between items-center">
        <div className="flex flex-row items-center">
          <div
            onClick={() => setExpand(!expand)}
            className="cursor-pointer flex flex-row items-center text-xl font-extrabold"
            style={{ width: "fit-content" }}
          >
            <div
              className={`${
                file.type === "dir" ? "folder" : "file"
              } flex flex-row justify-center items-center w-10 h-10 rounded-md mr-0 `}
            >
              <FolderIcon size={20} />
            </div>
            <div className="font-medium text-[16px]"> {name.replaceAll("_", "-")}</div>
          </div>
        </div>
      </div>
      {expand && (
        <div className="ml-8">
          {file.children.map((file: any, idx: number) => {
            return (
              <Folder
                key={idx}
                file={file}
                name={file.name.replaceAll("_", "-")}
                count={count}
                folderNames={folderNames}
              />
            )
          })}
          <div className="grid grid-cols-2  h-full max-h-[50vh] overflow-y-auto">
            {file.files.map((file: any) => {
              const previewURL = spaceBaseUrl + "/coloring-book-maker-preview/preview" + "/" + file.file.preview

              const imageUrl = file.file.fpath + "/" + file.file.filename

              return (
                <Block key={file.file.id} className="flex-shrink-0 cursor-pointer">
                  <LazyLoadImage
                    onClick={() => addObject(imageUrl)}
                    className="aspect-square h-20 w-full object-contain"
                    threshold={50}
                    alt={file.file.filename}
                    effect="blur"
                    src={previewURL}
                  />
                </Block>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default function () {
  const setIsSidebarOpen = useSetIsSidebarOpen()

  const [isLoading, setIsLoading] = React.useState(false)
  const [folders, setFolders] = React.useState<any>([])
  const [searchKey, setSearchKey] = useState<any>("")

  const [allImages, setAllImages] = useState<any[]>([])

  const [filteredImage, setFilteredImage] = useState<any[]>([])
  const [folderNames, setFolderNames] = useState<any[]>([])

  const dispatch = useDispatch()

  const imageAssetsData = useSelector(selectElementsData)

  const makeSearch = () => {
    if (searchKey.length < 2) return

    const minLength = searchKey.length - 1
    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: minLength < 1 ? 1 : minLength,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: ["fpath"],
    }

    const fuse = new Fuse(allImages, options)
    const filteredData = fuse.search(searchKey).map((item) => item.item)

    // var filteredData = allImages.filter(function (obj) {
    //   return obj["fpath"].search(new RegExp(searchKey, "i")) !== -1
    // })
    //  setSubTotalImages(perPage)
    generateAllFiles(filteredData, false)
    //  setImages(filteredData.slice(0, perPage))
    // setPageNumber(1)
    // setIsloading(true)
    // fetchData(true)
  }
  const generateAllFiles = (all: any, isShortEnabled = true) => {
    if (all.length === 0) return
    let root = convertFilesToFolderStructure(all)[0].children

    if (isShortEnabled) {
      const sortedObj = Object.fromEntries(Object.entries(root).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)))

      setFolders(sortedObj)
    } else {
      setFolders(root)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (searchKey === "") {
      setIsLoading(true)
      generateAllFiles(allImages)
      setFilteredImage([])
      setIsLoading(false)
    }
  }, [searchKey])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      let all = [] as any
      if (imageAssetsData.length == 0) {
        all = await getAllDigitalAssetsImages()
        // @ts-ignore
        dispatch(setElementsData(all))
      } else {
        all = imageAssetsData
      }

      setAllImages(all)
      generateAllFiles(all)
    })()
  }, [])

  console.log("folders", folders)

  return (
    <Block className="panel">
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem 1.5rem 0",
        }}
      >
        <Block className="title">Categories </Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <ChevronLeftCircle size={22} />
        </Block>
      </Block>
      <Block className="relative p-6">
        <Input
          onKeyDown={(key) => key.code === "Enter" && makeSearch()}
          onBlur={makeSearch}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search"
          className="inputv2 pr-10"
        />
        <div
          style={{
            right: "35px",
            top: "45%",
          }}
          onClick={makeSearch}
          className="absolute  top-1/2 transform -translate-y-1/2 cursor-pointer"
        >
          <SearchIcon className="w-5" />
        </div>
      </Block>
      <div className="scrollable">
        <div className="flex flex-row justify-center text-center w-full">
          {isLoading && <Spinner $size={SIZE.small} className="m-auto" />}
        </div>
        <div className="mx-2 overflow-y-auto" style={{ maxHeight: "calc(100vh - 300px)" }}>
          {Object.keys(folders).map((folder: any, index) => {
            return (
              <Folder
                key={index}
                file={folders[folder]}
                name={folders[folder].name.replaceAll("_", "-")}
                folderNames={folderNames}
              />
            )
          })}
        </div>
        {/* Folder not found */}
        {Object.keys(folders).length === 0 && (
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              {/* <img src={NoData} alt="No Data" className="w-32" /> */}
              <div className="text-gray-500">No Data Found</div>
            </div>
          </div>
        )}
      </div>
    </Block>
  )
}
