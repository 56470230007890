import { useStyletron, styled } from "baseui"
import { BASE_ITEMS } from "~/constants/app-options"
import useAppContext from "~/hooks/useAppContext"
import Icons from "~/components/Icons"
import { useTranslation } from "react-i18next"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import useEditorType from "~/hooks/useEditorType"
import Scrollable from "~/components/Scrollable"
import { Block } from "baseui/block"
import { authSelector, isOwnerSelector } from "~/store/slices/auth/selectors"
import { useDispatch, useSelector } from "react-redux"
import jsPDF from "jspdf"
import { useEditor } from "@layerhub-io/react"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import { selectFrameSize } from "~/store/slices/current-frame-size/selectors"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import DesignTitle from "../Navbar/DesignTitle"
import axios from "axios"
import TUser from "~/models/user"
import { Button } from "~/components/ui/button"
import { CloudDownloadIcon, BookIcon } from "lucide-react"

const Container = styled("div", (props) => ({
  width: "80px",

  display: "flex",
}))

const fetchInfo = async (token: string) => {
  const SERVER = import.meta.env.VITE_SERVER_URL
  const url = `${SERVER}/api/account`

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data.data
}

const logUsage = async () => {
  const base = "https://stingray-app-tx5jj.ondigitalocean.app"
  const url = `${base}/api/usage`
  const token = sessionStorage.getItem("token")

  if (!token) return

  const info = await fetchInfo(token)
  const owner = info.id

  try {
    await axios.post(url, { tool_id: "coloring-book", user_id: owner })
    console.log("Usage logged successfully")
  } catch (err) {
    console.error("Error logging usage", err)
  }
}

const PanelsList = () => {
  const { activePanel } = useAppContext()
  const { t } = useTranslation("editor")
  const isOwner = useSelector(isOwnerSelector).isOwner
  const owner = useSelector(authSelector)
  const dispatch = useDispatch()
  const editorType = useEditorType()
  const editor = useEditor()
  const currentSize = useSelector(selectFrameSize).currentSize
  const { setDisplayPreview, setScenes, setCurrentDesign, setCurrentScene, currentDesign, currentScene, scenes } =
    useDesignEditorContext()

  const flatPdf = async () => {
    if (!isOwner) {
      alert("To download pdf, one needs to purchase the Coloring Book Maker tool first.")
      return
    }

    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Preparing PDF...",
      })
    )

    editor.frame.frame.canvas.isDrawingMode = false
    editor.cancelContextMenuRequest()
    const currentScene = editor.scene.exportToJSON()

    // Initialize pdfDoc outside the loop
    const pdfDoc = new jsPDF({
      unit: "in",
      format: [currentSize.width, currentSize.height],
    })

    // Loop through scenes with a slight delay between each render
    for (let i = 0; i < scenes.length; i++) {
      await new Promise((resolve) => setTimeout(resolve, 50)) // allow UI to breathe

      const scn = scenes[i]
      const isCurrentScene = scn.id === currentScene.id
      const preview = isCurrentScene ? await editor.renderer.toDataURL(currentScene, {}) : scn.preview

      // Only add a new page if it's not the first scene
      if (i > 0) pdfDoc.addPage()

      pdfDoc.addImage(
        preview as string,
        0,
        0,
        pdfDoc.internal.pageSize.getWidth(),
        pdfDoc.internal.pageSize.getHeight(),
        undefined,
        "FAST"
      )
    }

    if (currentDesign) {
      pdfDoc.save(`${currentDesign.name}.pdf`)
    } else {
      console.info("NO CURRENT DESIGN")
    }

    dispatch(setLoadingStatus({ isLoading: false, title: "" }))

    // Logging usage
    logUsage()
  }

  const PANEL_ITEMS = BASE_ITEMS
  return (
    <Container
      style={{
        border: "2px solid #e7daf3",
        marginTop: "13px",
      }}
      className="left-panel-container   sp-container "
    >
      <Scrollable autoHide={true}>
        {PANEL_ITEMS.map((panelListItem) => (
          <PanelListItem
            label={t(`panels.panelsList.${panelListItem.id}`)}
            name={panelListItem.name}
            key={panelListItem.name}
            icon={panelListItem.name}
            activePanel={activePanel}
          />
        ))}
        <div className="">
          {isOwner ? (
            <div onClick={flatPdf} className="item ">
              <CloudDownloadIcon className="" />
              Download
            </div>
          ) : (
            <Button
              onClick={() => {
                window.open("http://www.selfpublishingtitans.com/coloring-book-maker", "_self")
              }}
            >
              Buy Now
            </Button>
          )}
        </div>
      </Scrollable>
    </Container>
  )
}

const PanelListItem = ({ label, icon, activePanel, name }: any) => {
  const { setActivePanel } = useAppContext()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()
  // @ts-ignore
  const Icon = Icons[icon]
  return (
    <Block
      id="EditorPanelList"
      className={`item ${activePanel === name ? "active" : ""}`}
      onClick={() => {
        setIsSidebarOpen(true)
        setActivePanel(name)
      }}
    >
      <Icon size={24} />
      <div>{label != "Search" ? label : "All Drawings"}</div>
    </Block>
  )
}

export default PanelsList
