import React from "react"
import { Block } from "baseui/block"
import Scrollable from "~/components/Scrollable"
import { HexColorPicker } from "react-colorful"
import { Delete } from "baseui/icon"
import { throttle } from "lodash"
import { useActiveObject, useEditor, useObjects } from "@layerhub-io/react"
import { ILayer } from "@layerhub-io/types"
import { v4 as uuidv4 } from "uuid"
import ComRange from "~/components/common/Range"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import useIsSidebarOpen from "~/hooks/useIsSidebarOpen"
import { ChevronLeftCircleIcon } from "lucide-react"

const PRESET_COLORS = [
  "#FFFFFF",
  "#000000",
  "#f44336",
  "#ff9800",
  "#ffee58",
  "#66bb6a",
  "#26a69a",
  "#03a9f4",
  "#3f51b5",
  "#673ab7",
  "#9c27b0",
  "#ec407a",
]

const Draw = () => {
  const [color, setColor] = React.useState("#f44336")
  const [strokeWidth, setStrokeWidth] = React.useState<number>(20)
  const [brushType, setBrushType] = React.useState<string>("pencil")
  const activeObject = useActiveObject()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const isSidebarOpen = useIsSidebarOpen()
  const editor = useEditor()
  const objects = useObjects() as ILayer[]

  const updateObjectFill = throttle((color: string) => {
    if (activeObject) {
      editor.objects.update({ fill: color })
    }

    setColor(color)
  }, 100)

  React.useEffect(() => {
    let watcher = async (e: any) => {
      e.path.set({
        fill: "transparent",
        id: uuidv4(),
        type: "StaticPath",
      })

      // editor.canvas.setBackgroundColor("transparent")

      editor.history.save()
      editor.cancelContextMenuRequest()
      editor.canvas.requestRenderAll()
    }
    if (editor) {
      editor.frame.frame.canvas.on("path:created", watcher)
    }
    return () => {
      if (editor) {
        editor.off("history:changed", watcher)
      }
    }
  }, [editor.frame.frame.canvas])

  React.useEffect(() => {
    editor.frame.frame.canvas.freeDrawingBrush.color = color
    editor.frame.frame.canvas.freeDrawingBrush.width = strokeWidth
    editor.frame.background.canvas.freeDrawingCursor = `url( ${getDrawCursor()} ) 
    ${strokeWidth / 2} ${strokeWidth / 2}, crosshair
    `
  }, [color, strokeWidth])

  const getDrawCursor = () => {
    const circle = `
		<svg
			height="${strokeWidth}"
			fill="${color}"
			viewBox="0 0 ${strokeWidth * 2} ${strokeWidth * 2}"
			width="${strokeWidth}"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="50%"
				cy="50%"
				r="${strokeWidth}" 
			/>
		</svg>
	`

    return `data:image/svg+xml;base64,${window.btoa(circle)}`
  }

  React.useEffect(() => {
    if (editor) {
      if (isSidebarOpen) {
        const canvas = editor.frame.frame.canvas
        canvas.on("mouse:move", function (e) {
          if (e.target && e.target.id) {
            canvas.isDrawingMode = true
          } else {
            canvas.isDrawingMode = false
          }
        })
      } else {
        editor.frame.frame.canvas.off("mouse:move")
        editor.frame.frame.canvas.isDrawingMode = false

        editor.cancelContextMenuRequest()
      }
    }
  }, [editor.frame.frame.canvas, isSidebarOpen])

  React.useEffect(() => {
    return () => {
      const canvas = editor.frame.frame.canvas
      canvas.off("mouse:move")
      canvas.off("path:created")
      canvas.isDrawingMode = false
      editor.cancelContextMenuRequest()
    }
  }, [])

  return (
    <Block className="panel" $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem",
        }}
      >
        <Block className="title">Draw</Block>

        <Block
          $style={{ cursor: "pointer", display: "flex" }}
          onClick={() => {
            setIsSidebarOpen(false)

            // editor.frame.frame.canvas.isDrawingMode = false
            // editor.cancelContextMenuRequest()
          }}
        >
          <ChevronLeftCircleIcon size={24} />
        </Block>
      </Block>
      <Scrollable>
        <Block padding="0 1.5rem">
          <Block>
            <ComRange
              label={"Stroke size"}
              options={{
                min: 1,
                max: 100,
              }}
              value={strokeWidth}
              setValue={setStrokeWidth}
            />
            {/* <ComSelect
              label="Brush type"
              options={[
                { label: "Pencil", value: "PencilBrush" },
                { label: "Circle", value: "CircleBrush" },
                { label: "Spray", value: "SprayBrush" },
                { label: "Pattern", value: "PatternBrush" },
                { label: "Hatch", value: "HatchBrush" },
              ]}
              value={brushType}
              setValue={setBrushType}
            /> */}
          </Block>
          <HexColorPicker color={color} onChange={updateObjectFill} style={{ width: "100%" }} />
          <Block>
            <Block $style={{ padding: "0.75rem 0", fontWeight: 500, fontSize: "14px" }}>Preset colors</Block>
            <Block $style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gap: "0.25rem" }}>
              {PRESET_COLORS.map((colorItem, index) => (
                <Block
                  $style={{
                    cursor: "pointer",
                    borderRadius: "4px",
                    border: ` solid #03a9f4 ${colorItem === color ? "4px" : "1px"}`,
                  }}
                  onClick={() => updateObjectFill(colorItem)}
                  backgroundColor={colorItem}
                  height="38px"
                  key={index}
                />
              ))}
            </Block>
          </Block>
        </Block>
      </Scrollable>
    </Block>
  )
}

export default Draw
