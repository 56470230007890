import React, { Fragment } from "react"
import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import Scrollable from "~/components/Scrollable"
import { Button } from "~/components/ui/button"
import DropZone from "~/components/Dropzone"
import { useEditor } from "@layerhub-io/react"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { nanoid } from "nanoid"
import { ILayer, IScene } from "@layerhub-io/types"
import { deleteFile, listFiles, upload as uploadImage, usage as usageReq } from "~/services/cloud-service"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { syncDataByUserId } from "~/services/digital-assets"
import { useDispatch, useSelector } from "react-redux"
import { openSnackBar } from "~/store/slices/snackbar/actions"
import { uploadedImagesClassDB } from "~/indexDB/db"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import { selectedProject } from "~/store/slices/project/selectors"
import {
  ArrowLeftCircleIcon,
  CloudDownloadIcon,
  CloudUploadIcon,
  RotateCcwIcon,
  RotateCwIcon,
  RotateCwSquare,
  TrashIcon,
} from "lucide-react"
import { Separator } from "~/components/ui/separator"
import { EllipsisVerticalIcon } from "lucide-react"
import { Progress } from "~/components/ui/progress"

const bytesToValue = (bytes: number) => {
  if (bytes < 1024) {
    return `${bytes} B`
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(0)} KB`
  }

  if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`
  }

  return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`
}

interface ImageContextMenuProps {
  setShowContextMenu: (value: boolean) => void
  position: { x: number; y: number }
  token: string
  bucket: string
  file_path: string
  full_url: string
  uploads: any
  setUploads: any
  sourceType: string
}

const ImageContextMenu = ({
  setShowContextMenu,
  position,
  token,
  bucket,
  file_path,
  full_url,
  uploads,
  setUploads,
  sourceType,
}: ImageContextMenuProps) => {
  const scenes = useDesignEditorPages()
  const editor = useEditor()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const dispatch = useDispatch()
  const wrapperRef = React.useRef<any>(null)
  const project_id = useSelector(selectedProject).id

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
        setShowContextMenu(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  const handleLocalDelete = async () => {
    setShowContextMenu(false)
    const newUploads = uploads.filter((upload: any) => {
      return upload.src !== full_url
    })
    setUploads(newUploads)
    uploadedImagesClassDB.images.update(1, { images: newUploads })
  }

  const handleDelete = async () => {
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Deleting...",
      })
    )
    if (sourceType === "Local") {
      handleLocalDelete()
      dispatch(
        setLoadingStatus({
          isLoading: false,
          title: "",
        })
      )
      return
    }
    // delete image
    setShowContextMenu(false)
    const res = await deleteFile(file_path, bucket, token)

    if (res.status !== 200) {
      dispatch(
        openSnackBar({
          title: "Error",
          message: "Error deleting image",
          KIND: "error",
          timeout: 3000,
        })
      )
      return
    }

    // update scenes
    for (let i = 0; i < scenes.length; i++) {
      const scene = scenes[i]
      const layers = scene.layers.filter((layer: any) => {
        return layer.src !== full_url
      })
      scene.layers = layers
      const updatedPreview = (await editor.renderer.render(scene)) as string
      scene.preview = updatedPreview
    }

    if (currentScene) {
      const layers = currentScene.layers.filter((layer: any) => {
        return layer.src !== full_url
      })
      currentScene.layers = layers
      const updatedPreview = (await editor.renderer.render(currentScene)) as string
      setCurrentScene({ ...currentScene, preview: updatedPreview })
    }

    // set scenes
    setScenes([...scenes])

    // update uploads
    const newUploads = uploads.filter((upload: any) => {
      return upload.src !== full_url
    })
    setUploads(newUploads)

    // update cloud data
    syncDataByUserId({
      token: token,
      data: scenes,
      project_id: project_id,
    })
      .then((res) => {
        dispatch(
          openSnackBar({
            title: "Success",
            message: "Image deleted successfully",
            KIND: "success",
            timeout: 3000,
          })
        )
      })
      .catch((err) => {
        dispatch(
          openSnackBar({
            title: "Error",
            message: "Error deleting image",
            KIND: "error",
            timeout: 3000,
          })
        )
      })

    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
  }

  const handleDownload = () => {
    // download image
    setShowContextMenu(false)
    // download using full_url using javascript
    const link = document.createElement("a")
    link.href = full_url
    link.download = "image.png"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div
      ref={wrapperRef}
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 1000,
        willChange: "transform",
        transform: `translate(${position.x}px, ${position.y}px)`,
      }}
    >
      <div onClick={handleDelete}>
        <div>
          <TrashIcon />
        </div>
      </div>
      <Separator />
      <Button onClick={handleDownload}>
        <CloudDownloadIcon />
      </Button>
    </div>
  )
}

interface ImageDivProps {
  src: string
  onClick: () => void
  token: string
  bucket: string
  file_path: string
  full_url: string
  uploads: any
  setUploads: any
  sourceType: string
}

const ImageDiv = ({
  src,
  onClick,
  token,
  bucket,
  file_path,
  full_url,
  uploads,
  setUploads,
  sourceType,
}: ImageDivProps) => {
  const [showContextMenu, setShowContextMenu] = React.useState(false)

  // get image position
  const [position, setPosition] = React.useState({ x: 0, y: 0 })
  const imgRef = React.useRef<HTMLImageElement>(null)

  return (
    <Fragment>
      {showContextMenu && (
        <ImageContextMenu
          setShowContextMenu={setShowContextMenu}
          position={position}
          token={token}
          bucket={bucket}
          file_path={file_path}
          full_url={full_url}
          uploads={uploads}
          setUploads={setUploads}
          sourceType={sourceType}
        />
      )}
      <div
        style={{
          position: "relative",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        onClick={onClick}
        onContextMenu={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setPosition({ x: e.clientX + 20, y: e.clientY })
          setShowContextMenu(true)
        }}
      >
        {/* show menu icon on the top right corner */}
        <div className="" style={{ position: "absolute", top: 0, right: 0 }}>
          {/* <Button
            style={{
              padding: "0.0rem",
            }}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              setPosition({ x: e.clientX + 20, y: e.clientY })
              setShowContextMenu(true)
            }}
          > */}
          <EllipsisVerticalIcon
            className="w-4"
            onClick={(e) => {
              e.stopPropagation()
              setPosition({ x: e.clientX + 20, y: e.clientY })
              setShowContextMenu(true)
            }}
          />
          {/* </Button> */}
        </div>

        <div>
          <img className="rounded-lg" width="100%" ref={imgRef} src={src} alt="preview" />
        </div>
      </div>
    </Fragment>
  )
}

export default function () {
  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const [uploads, setUploads] = React.useState<any>([])
  const editor = useEditor()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const baseUrl = import.meta.env.VITE_R2_COLORING_BOOKS_IMAGE_URL
  const [usage, setUsage] = React.useState<any>(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const token = sessionStorage.getItem("token")

  React.useEffect(() => {
    ;(async () => {
      uploadedImagesClassDB.images.get(1).then((data) => {
        if (data) {
          const images = data.images.map((image: any) => {
            return {
              id: image.id,
              src: image.src,
              preview: image.preview,
              type: "StaticImage",
              sourceType: "Local",
            }
          })
          setUploads(images)
        }
      })

      if (!token) {
        return
      }
      const allImages = await listFiles("coloring-books", token)
      let allImageUrls: any = []

      if (allImages?.data?.data?.length ?? 0) {
        allImages?.data?.data.forEach((image: any) => {
          allImageUrls.push({
            id: image.id,
            src: baseUrl + "/" + image.file_path,
            preview: baseUrl + "/" + image.file_path,
            type: "StaticImage",
            sourceType: "Cloud",
          })
        })
      }
      // setUploads(allImageUrls)
      setUploads((prev: any) => [...prev, ...allImageUrls])

      const usageRes = await usageReq(token)
      setUsage(usageRes.data.data)
    })()
  }, [])

  const handleDropFiles = async (files: FileList) => {
    const newUploads = []

    if (!token) {
      return
    }
    setIsLoading(true)

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      try {
        const res = await uploadImage(file, "coloring-books", token)
        // upload image
        const url = res.data.data.file_path
        const type = "StaticImage"

        const upload = {
          id: nanoid(),
          src: baseUrl + "/" + url,
          preview: baseUrl + "/" + url,
          type: type,
          sourceType: "Cloud",
        }
        newUploads.push(upload)
      } catch (error: any) {
        alert(error.response.data.error || "Error uploading image")
      }
    }

    setUploads([...uploads, ...newUploads])

    setIsLoading(false)
  }

  const handleInputFileRefClick = () => {
    inputFileRef.current?.click()
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDropFiles(e.target.files!)
  }

  const addImageToCanvas = (props: Partial<ILayer>) => {
    editor.objects.add(props).then(() => {
      editor.objects.scale("fit", props.id!)
    })
  }

  // TODO: delete image from cloud
  // const deleteAll = async () => {
  //   setUploads([])
  //   await uploadedImagesClassDB.images.clear()
  // }
  return (
    <DropZone handleDropFiles={handleDropFiles}>
      <Block className="panel" $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Block
          $style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            justifyContent: "space-between",
            padding: "1.5rem",
          }}
        >
          <Block className="title">Uploads</Block>

          <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
            <ArrowLeftCircleIcon size={22} />
          </Block>
        </Block>
        <Block padding={"0 1.5rem"} margin={"1rem 0rem"}>
          <h6 className="font-medium text-sm">
            {bytesToValue(usage.used)} / {bytesToValue(usage.total)} used
          </h6>
          <Progress className="mt-2" value={(usage.used / usage.total) * 100} />
        </Block>
        <Scrollable>
          <Block padding={"0 1.5rem"}>
            <Button className="cursor-pointer" variant={"outline"} onClick={handleInputFileRefClick}>
              Upload
              <CloudUploadIcon />
              {isLoading && (
                <RotateCwIcon className="animate-spin text-blue-500" size={20} style={{ marginLeft: "0.5rem" }} />
              )}
            </Button>
            {/* <Button
              onClick={deleteAll}
              size={SIZE.compact}
              overrides={{
                Root: {
                  style: {
                    marginTop: "0.5rem",
                    width: "100%",
                    backgroundColor: "#f50057",
                  },
                },
              }}
            >
              Delete All
            </Button> */}
            <input
              onChange={handleFileInput}
              type="file"
              id="file"
              multiple
              accept="image/*"
              ref={inputFileRef}
              style={{ display: "none" }}
            />

            <div
              style={{
                marginTop: "1rem",
                display: "grid",
                gap: "0.5rem",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {uploads.map((upload: any) => {
                return (
                  <ImageDiv
                    key={upload.id}
                    src={upload.src}
                    onClick={() => {
                      addImageToCanvas(upload)
                    }}
                    token={sessionStorage.getItem("token")!}
                    bucket="coloring-books"
                    file_path={upload.src.replace(baseUrl + "/", "")}
                    full_url={upload.src}
                    uploads={uploads}
                    setUploads={setUploads}
                    sourceType={upload.sourceType}
                  />
                )
              })}
            </div>
          </Block>
        </Scrollable>
      </Block>
    </DropZone>
  )
}
